const React = require("react")
const { Provider } = require("react-redux")
const store = require("./src/ducks/store")

const Wrapper = ({element}) => {
  return (
    <Provider store={store.default}>
      {element}
    </Provider>
  )
}
export default Wrapper